import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";

const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];

const footerNavigation = {
  solutions: [
    { name: "Nos Solutions Pour Votre Organisation", href: "/sol1" },
    { name: "Nos Solutions Pour Votre Equipe Officinale", href: "/sol2" },
    { name: "Nos Solutions Pour Vos Patients", href: "/sol3" },
  ],

  support: [
    { name: "Qui sommes-nous ?", href: "/aboutus" },
    { name: "Notre Mission", href: "/mission" },
    { name: "Notre vision", href: "/vision" },
    { name: "Nos valeurs", href: "/valeur" },
  ],
  company: [
    { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
    { name: "Ka-psul", href: "https://ka-psul.com/" },
  ],
  contact: [{ name: "Rejoignez-nous ", href: "/contact" }],
  social: [
    {
      name: "Facebook",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Facebook",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          {" "}
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.42857 8.96884H13.1429V10.8193C13.6783 9.75524 15.0503 8.79887 17.1114 8.79887C21.0623 8.79887 22 10.9167 22 14.8028V22H18V15.6878C18 13.4748 17.4646 12.2266 16.1029 12.2266C14.2143 12.2266 13.4286 13.5722 13.4286 15.6878V22H9.42857V8.96884ZM2.57143 21.83H6.57143V8.79887H2.57143V21.83ZM7.14286 4.54958C7.14286 4.88439 7.07635 5.21593 6.94712 5.52526C6.81789 5.83458 6.62848 6.11565 6.3897 6.3524C6.15092 6.58915 5.86745 6.77695 5.55547 6.90508C5.24349 7.0332 4.90911 7.09915 4.57143 7.09915C4.23374 7.09915 3.89937 7.0332 3.58739 6.90508C3.27541 6.77695 2.99193 6.58915 2.75315 6.3524C2.51437 6.11565 2.32496 5.83458 2.19574 5.52526C2.06651 5.21593 2 4.88439 2 4.54958C2 3.87339 2.27092 3.22489 2.75315 2.74675C3.23539 2.26862 3.88944 2 4.57143 2C5.25341 2 5.90747 2.26862 6.3897 2.74675C6.87194 3.22489 7.14286 3.87339 7.14286 4.54958Z"
          ></path>{" "}
        </svg>
      ),
    },
  ],
};

export default function HomePage() {
  const handleClick = () => {
    const paragraph = document.getElementById("paragraphe-cible");
    if (paragraph) {
      paragraph.scrollIntoView({ behavior: "smooth" });
    }
  };

  const targetRef = useRef(null);

  const handleScroll = (event) => {
    event.preventDefault();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }
  const ref1 = useRef();
  const isVisible1 = useIsVisible(ref1);

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);

  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);

  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);

  const ref8 = useRef();
  const isVisible8 = useIsVisible(ref8);

  const ref9 = useRef();
  const isVisible9 = useIsVisible(ref9);

  const ref10 = useRef();
  const isVisible10 = useIsVisible(ref10);

  const ref11 = useRef();
  const isVisible11 = useIsVisible(ref11);

  const ref12 = useRef();
  const isVisible12 = useIsVisible(ref12);

  const ref13 = useRef();
  const isVisible13 = useIsVisible(ref13);

  const ref14 = useRef();
  const isVisible14 = useIsVisible(ref14);

  const ref15 = useRef();
  const isVisible15 = useIsVisible(ref15);

  const ref16 = useRef();
  const isVisible16 = useIsVisible(ref16);

  const ref17 = useRef();
  const isVisible17 = useIsVisible(ref17);

  const ref18 = useRef();
  const isVisible18 = useIsVisible(ref18);

  const ref19 = useRef();
  const isVisible19 = useIsVisible(ref19);

  const ref20 = useRef();
  const isVisible20 = useIsVisible(ref20);

  const ref21 = useRef();
  const isVisible21 = useIsVisible(ref21);

  const ref22 = useRef();
  const isVisible22 = useIsVisible(ref22);

  const ref23 = useRef();
  const isVisible23 = useIsVisible(ref23);

  const ref24 = useRef();
  const isVisible24 = useIsVisible(ref24);

  const ref25 = useRef();
  const isVisible25 = useIsVisible(ref25);

  const ref26 = useRef();
  const isVisible26 = useIsVisible(ref26);

  const ref27 = useRef();
  const isVisible27 = useIsVisible(ref27);

  const ref28 = useRef();
  const isVisible28 = useIsVisible(ref28);

  const ref29 = useRef();
  const isVisible29 = useIsVisible(ref29);

  const ref30 = useRef();
  const isVisible30 = useIsVisible(ref30);

  const ref31 = useRef();
  const isVisible31 = useIsVisible(ref31);

  const ref32 = useRef();
  const isVisible32 = useIsVisible(ref32);

  const ref33 = useRef();
  const isVisible33 = useIsVisible(ref33);

  const ref34 = useRef();
  const isVisible34 = useIsVisible(ref34);

  const ref35 = useRef();
  const isVisible35 = useIsVisible(ref35);

  const ref36 = useRef();
  const isVisible36 = useIsVisible(ref36);

  const ref37 = useRef();
  const isVisible37 = useIsVisible(ref37);

  const ref38 = useRef();
  const isVisible38 = useIsVisible(ref38);

  const ref211 = useRef();
  const isVisible211 = useIsVisible(ref211);

  const ref212 = useRef();
  const isVisible212 = useIsVisible(ref212);

  const ref213 = useRef();
  const isVisible213 = useIsVisible(ref213);

  const ref214 = useRef();
  const isVisible214 = useIsVisible(ref214);

  return (
    <div className="bg-white">
      <Helmet>
        <title>KA Consulting And Pharmacy Management</title>
        <meta name="description" content="Ma description" />
        {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
      </Helmet>
      <div className="relative overflow-hidden">
        <header className="relative bg-teal-800 pb-60 sm:pb-60">
          <div className="absolute inset-0">
            <img
              className="  h-full w-full object-cover"
              src="./images/img/aq.webp"
              alt=""
            />
            <div
              className="absolute inset-0 bg-gradient-to-l from-zinc-500 to-gray-400 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <Popover as="div" className="relative z-10">
            <nav
              className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
              aria-label="Global"
            >
              <div className="flex w-full items-center justify-between lg:w-auto">
                <a href="/">
                  <span className="sr-only">
                    KA Consulting And Pharmacy Management{" "}
                  </span>
                  <img
                    className="hover:animate-wiggle-more  hover:animate-infinite h-28 w-auto "
                    src="./images/lo.png"
                    alt=""
                  />
                </a>
                <div className="-mr-2 flex items-center lg:hidden">
                  <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-100 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                    <span className="sr-only">Menu</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>

              <div className="hidden   lg:flex lg:gap-x-12">
                <a
                  href="/aboutus"
                  className="text-sm font-semibold leading-6  text-teal-300"
                >
                  Qui sommes-nous ?
                </a>
                <a
                  href="/about"
                  className="text-sm font-semibold leading-6 text-teal-300"
                >
                  Notre identité de marque
                </a>

                <a
                  href="/solution"
                  className="text-sm font-semibold leading-6  text-teal-300"
                >
                  Nos solutions
                </a>
                <a
                  href="/merchan"
                  className="text-sm font-semibold leading-6  text-teal-300"
                >
                  Merchandising et PLV Pharmacie
                </a>
                <a
                  href="https://ka-psul.com/"
                  className="text-sm font-semibold leading-6  text-teal-300"
                >
                  Ka-psul
                </a>
              </div>
              <div className="hidden lg:flex lg:items-center lg:space-x-6">
                <a
                  href="/contact"
                  className="rounded-md border border-transparent bg-white bg-opacity-60 py-2 px-6 text-base font-medium text-teal-400 hover:bg-opacity-80 hover: text-teal-800"
                >
                  Contact
                </a>
              </div>
            </nav>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="./images/lo.png"
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="pt-5 pb-6">
                    <div className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <div className="mt-6 px-5">
                      <a
                        href="/contact"
                        className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                      >
                        Contact
                      </a>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <div class="px-6 py-12 text-center md:px-12 lg:my-12 lg:text-left">
            <div class="w-100 mx-auto sm:max-w-2xl md:max-w-4xl lg:max-w-5xl xl:max-w-7xl">
              <div class="grid items-center gap-12 lg:grid-cols-2">
                <div class="relative lg:mt-0">
                  <h1 class="animate-fade-right animate-infinite animate-duration-[5000ms] animate-delay-[500ms] text-center text-white text-4xl mt-10 font-bold tracking-tight xl:text-5xl">
                    <p>Êtes-vous prêt à propulser</p>
                    <p class="text-teal-300">Votre Pharmacie</p>
                    <p>vers de nouveaux sommets ?</p>
                  </h1>
                  <div class="flex-row md:flex">
                    <div class="relative mr-2   grow md:mb-0"></div>
                  </div>
                </div>

                <div class=" lg:mb-0">
                  <div class="embed-responsive embed-responsive-16by9 relative w-full overflow-hidden rounded-lg shadow-lg">
                    <div
                      id="responsiveVideoWrapper"
                      class="relative h-0 pb-fluid-video"
                    >
                      <div className="max-w-screen-lg mx-auto p-4">
                        <video
                          className="h-full w-full rounded-lg"
                          controls
                          autoPlay
                          loop
                          muted
                          cloudName="demo"
                          publicId="elephants"
                          key="elephants"
                          videoCodec={"auto"}
                        >
                          <source
                            src="https://firebasestorage.googleapis.com/v0/b/pharma-9fd2a.appspot.com/o/intro%20-%20final.mp4?alt=media&token=e95e07ed-331b-4dea-804d-ea835217a5c9"
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative flex justify-center mt-16 px-8">
                <a
                  class="w-26 rounded-md border flex justify-center border-transparent bg-white bg-opacity-90 py-2 px-4 text-base font-medium text-teal-800 hover:bg-opacity-80 hover:text-teal-400"
                  onClick={handleClick}
                >
                  Allons-y !
                </a>
              </div>
            </div>
          </div>
        </header>

        <main>
          <div>
            <div
              id="paragraphe-cible"
              className="relative  py-16 sm:py-24 lg:py-32"
            >
              <div className="relative">
                <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                  <p
                    ref={ref16}
                    className={`font-serif  text-2xl font-bold tracking-tight text-gray-700 sm:text-4xl ${
                      isVisible16
                        ? "animate-fade-right animate-duration-800 animate-delay-200 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    Qui sommes-nous ?{" "}
                  </p>
                </div>
                <div className="mx-auto mt-12 grid max-w-md gap-8 px-6 sm:max-w-lg lg:max-w-7xl lg:grid-cols-4 lg:px-8">
                  <a
                    href="/sone"
                    div
                    ref={ref16}
                    className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2  hover:animate-wiggle hover:animate-infinite ${
                      isVisible16
                        ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <img
                      src="./images/img/1.jpg"
                      alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                      className="object-cover object-center group-hover:opacity-75"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                      aria-hidden="true"
                    />
                    <div
                      aria-hidden="true"
                      className="bg-gradient-to-b from-transparent to-black opacity-50"
                    />
                    <div className="flex items-end p-6">
                      <div
                        ref={ref17}
                        className={`    ${
                          isVisible17
                            ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="font-semibold text-white">
                          <span className="absolute inset-0" />
                          Votre Pharmacie, Votre Entreprise !
                        </h3>
                      </div>
                    </div>
                  </a>

                  <a
                    href="/stwo"
                    ref={ref18}
                    className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2 hover:animate-wiggle hover:animate-infinite   ${
                      isVisible18
                        ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <img
                      src="./images/img/2.jpg"
                      alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                      className="object-cover object-center group-hover:opacity-75"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                      aria-hidden="true"
                    />
                    <div
                      aria-hidden="true"
                      className="bg-gradient-to-b from-transparent to-black opacity-50"
                    />
                    <div className="flex items-end p-6">
                      <div
                        ref={ref22}
                        className={`    ${
                          isVisible22
                            ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="font-semibold text-white">
                          <a href="/stwo">
                            <span className="absolute inset-0" />
                            Et Si Nos Solutions Devenaient Les Vôtres !
                          </a>
                        </h3>
                      </div>
                    </div>
                  </a>

                  <a
                    href="/sthree"
                    ref={ref23}
                    className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2  hover:animate-wiggle hover:animate-infinite ${
                      isVisible23
                        ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <img
                      src="./images/img/3.jpg"
                      alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                      className="object-cover object-center group-hover:opacity-75"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                      aria-hidden="true"
                    />
                    <div
                      aria-hidden="true"
                      className="bg-gradient-to-b from-transparent to-black opacity-50"
                    />
                    <div className="flex items-end p-6">
                      <div
                        ref={ref24}
                        className={`    ${
                          isVisible24
                            ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="font-semibold text-white">
                          <a href="/sthree">
                            <span className="absolute inset-0" />
                            Vos défis
                          </a>
                        </h3>
                      </div>
                    </div>
                  </a>

                  <a
                    href="/sfort"
                    ref={ref25}
                    className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2  hover:animate-wiggle hover:animate-infinite ${
                      isVisible25
                        ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <img
                      src="./images/img/aq.png"
                      alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                      className="object-cover object-center group-hover:opacity-75"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                      aria-hidden="true"
                    />
                    <div
                      aria-hidden="true"
                      className="bg-gradient-to-b from-transparent to-black opacity-50"
                    />
                    <div className="flex items-end p-6">
                      <div
                        ref={ref26}
                        className={`    ${
                          isVisible26
                            ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="font-semibold text-white">
                          <a href="/sfort">
                            <span className="absolute inset-0" />
                            Nos atouts
                          </a>
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>{" "}
              <div className="mt-10 mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                {" "}
                <p
                  ref={ref214}
                  className={`font-serif    font-bold tracking-tight text-gray-700 sm:text-2xl ${
                    isVisible214
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Alors n’attendez plus, faites appel à notre expertise dès
                  maintenant !
                </p>
                <p
                  ref={ref213}
                  className={`font-serif    font-bold tracking-tight text-gray-700 sm:text-2xl ${
                    isVisible213
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Parce que nous réinventons votre succès
                </p>
              </div>
            </div>{" "}
          </div>

          <div className="relative  px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28   bg-gradient-to-l from-zinc-600 to-teal-300">
            <div className="absolute inset-0">
              <div className="h-1/3   sm:h-2/3" />
            </div>
            <div className="relative mx-auto max-w-7xl">
              <div className="text-center">
                <h2
                  ref={ref7}
                  className={`text-3xl font-bold tracking-tight text-white sm:text-4xl ${
                    isVisible7
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Notre identité de marque
                </h2>
              </div>
              <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
                <div
                  ref={ref4}
                  className={` flex flex-col overflow-hidden rounded-lg shadow-lg   hover:animate-wiggle hover:animate-infinite ${
                    isVisible4
                      ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flex-shrink-0">
                    <img
                      className="object-cover object-center group-hover:opacity-75 h-48 w-full  "
                      src="./images/img/mission.jpg"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between   p-6">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-100"></p>

                      <p className="text-2xl font-semibold text-gray-100  text-center ">
                        Notre Mission
                      </p>
                      <p className="mt-3 text-base text-sm text-gray-200">
                        Nous accompagnons les pharmacies d'officine dans leur
                        quête permanente de qualité et de prospérité ...{" "}
                      </p>
                    </div>
                    <div className="mt-6 flex items-center justify-center">
                      <div className="  flex items-right justify-right gap-x-6">
                        <a
                          href="/mission"
                          className="flex items-center justify-center rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-gray-800 shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 text-sm  focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          En Savoir Plus
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  ref={ref5}
                  className={` flex flex-col overflow-hidden rounded-lg shadow-lg   hover:animate-wiggle hover:animate-infinite ${
                    isVisible5
                      ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flex-shrink-0">
                    <img
                      className="object-cover object-center group-hover:opacity-75 h-48 w-full  "
                      src="./images/img/vision.jpg"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between   p-6">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-100"></p>

                      <p className="text-2xl font-semibold text-gray-100  text-center ">
                        Notre Vision
                      </p>
                      <p className="mt-3 text-base text-sm text-gray-200">
                        Devenir le partenaire de confiance et l’allié
                        incontournable ...
                      </p>
                    </div>
                    <div className="mt-6 flex items-center justify-center ">
                      <div className=" flex items-right justify-right gap-x-6">
                        <a
                          href="/vision"
                          className="flex items-center justify-center rounded-md bg-white px-3.5 py-1.5 text-base text-sm font-semibold leading-7 text-gray-800 shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          En Savoir Plus
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  ref={ref6}
                  className={`    flex-col overflow-hidden rounded-lg shadow-lg   hover:animate-wiggle hover:animate-infinite ${
                    isVisible6
                      ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <div className="flex-shrink-0">
                    <img
                      className="object-cover object-center group-hover:opacity-75 h-48 w-full  "
                      src="./images/img/valeur.jpg"
                      alt=""
                    />
                  </div>
                  <div className="flex flex-1 flex-col justify-between   p-6">
                    <div className="flex-1 ">
                      <p className="text-sm font-medium text-indigo-100"></p>

                      <p className="text-2xl font-semibold text-gray-100  text-center ">
                        Nos Valeurs
                      </p>
                      <p className="mt-3 text-base text-sm text-gray-200">
                        Professionnalisme ,Innovation , Intégrité ...{" "}
                      </p>
                    </div>
                    <div className="mt-6 flex items-center justify-center ">
                      <div className="mt-10 flex items-right justify-right gap-x-6">
                        <a
                          href="/valeur"
                          className="flex items-center justify-center rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-gray-800 shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 text-sm  focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          En Savoir Plus
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10 mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                {" "}
                <p
                  ref={ref37}
                  className={`font-serif    font-bold tracking-tight text-white sm:text-2xl ${
                    isVisible37
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Alors n’attendez plus, faites appel à notre expertise dès
                  maintenant !
                </p>
                <p
                  ref={ref37}
                  className={`font-serif    font-bold tracking-tight text-white sm:text-2xl ${
                    isVisible37
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Parce que nous réinventons votre succès
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="relative  py-16 sm:py-24 lg:py-32">
              <div className="relative">
                <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                  <p
                    ref={ref35}
                    className={`font-serif  text-4xl font-bold tracking-tight text-gray-700 sm:text-5xl ${
                      isVisible35
                        ? "animate-fade-right animate-duration-800 animate-delay-200 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    Nos Solutions{" "}
                  </p>
                </div>
                <div className="mx-auto mt-12 grid max-w-md gap-8 px-6 sm:max-w-lg lg:max-w-7xl lg:grid-cols-3 lg:px-8">
                  <a
                    href="/sol1"
                    ref={ref34}
                    className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2  hover:animate-wiggle hover:animate-infinite ${
                      isVisible34
                        ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <img
                      src="./images/img/9.jpg"
                      alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                      className="object-cover object-center group-hover:opacity-75"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                      aria-hidden="true"
                    />
                    <div
                      aria-hidden="true"
                      className="bg-gradient-to-b from-transparent to-black opacity-50"
                    />
                    <div className="flex items-end p-6">
                      <div
                        ref={ref33}
                        className={`    ${
                          isVisible33
                            ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="font-semibold text-white">
                          <a href="/sol1">
                            <span className="absolute inset-0" />
                            Nos Solutions Pour Votre Organisation
                          </a>
                        </h3>

                        <p
                          aria-hidden="true"
                          className="mt-1 text-sm text-white"
                        >
                          Á moindre effort pour un grand bénéfice !
                        </p>
                      </div>
                    </div>
                  </a>

                  <a
                    href="/sol2"
                    ref={ref32}
                    className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2 hover:animate-wiggle hover:animate-infinite   ${
                      isVisible32
                        ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <img
                      src="./images/img/8.jpg"
                      alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                      className="object-cover object-left group-hover:opacity-75"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                      aria-hidden="true"
                    />
                    <div
                      aria-hidden="true"
                      className="bg-gradient-to-b from-transparent to-black opacity-50"
                    />
                    <div className="flex items-end p-6">
                      <div
                        ref={ref31}
                        className={`    ${
                          isVisible31
                            ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="font-semibold text-white">
                          <a href="/sol2">
                            <span className="absolute inset-0" />
                            Nos Solutions Pour Votre Equipe Officinale
                          </a>
                        </h3>

                        <p
                          aria-hidden="true"
                          className="mt-1 text-sm text-white"
                        >
                          {" "}
                          Votre personnel est l’image de votre pharmacie et une
                          pièce clé de son succès !
                        </p>
                      </div>
                    </div>
                  </a>

                  <a
                    href="/sol3"
                    ref={ref30}
                    className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2  hover:animate-wiggle hover:animate-infinite ${
                      isVisible30
                        ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                        : "opacity-0 invisible   "
                    }`}
                  >
                    <img
                      src="./images/img/16.jpg"
                      alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                      className="object-cover object-center group-hover:opacity-75"
                    />
                    <div
                      className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                      aria-hidden="true"
                    />
                    <div
                      aria-hidden="true"
                      className="bg-gradient-to-b from-transparent to-black opacity-50"
                    />
                    <div className="flex items-end p-6">
                      <div
                        ref={ref29}
                        className={`    ${
                          isVisible29
                            ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                            : "opacity-0 invisible   "
                        }`}
                      >
                        <h3 className="font-semibold text-white">
                          <a href="/sol3">
                            <span className="absolute inset-0" />
                            Nos Solutions Pour Vos Patients
                          </a>
                        </h3>

                        <p
                          aria-hidden="true"
                          className="mt-1 text-sm text-white"
                        >
                          Laissez vos patients parler pour vous !
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className="mt-10 mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                {" "}
                <p
                  ref={ref27}
                  className={`font-serif    font-bold tracking-tight text-gray-700 sm:text-2xl ${
                    isVisible27
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Alors n’attendez plus, faites appel à notre expertise dès
                  maintenant !
                </p>
                <p
                  ref={ref211}
                  className={`font-serif    font-bold tracking-tight text-gray-700 sm:text-2xl ${
                    isVisible211
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Parce que nous réinventons votre succès
                </p>
              </div>
            </div>{" "}
          </div>

          <section aria-labelledby="cause-heading">
            <div className="relative bg-gray-800 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
              <div className="absolute inset-0 overflow-hidden">
                <img
                  src="./images/img/14.jpg"
                  alt=""
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div
                aria-hidden="true"
                className="absolute inset-0 bg-gray-900 bg-opacity-50"
              />
              <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
                <h2
                  id="cause-heading"
                  ref={ref21}
                  className={`text-3xl font-bold tracking-tight text-white sm:text-4xl font-serif hover:animate-wiggle hover:animate-infinite ${
                    isVisible21
                      ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                      : "opacity-0 invisible"
                  }`}
                >
                  Merchandising et PLV Pharmacie
                </h2>
                <p
                  ref={ref19}
                  className={`mt-3 text-l text-white hover:animate-infinite ${
                    isVisible19
                      ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                      : "opacity-0 invisible"
                  }`}
                >
                  Laissez la magie opérer et transformez un seul produit en une
                  expérience d’achat inoubliable !
                </p>
                <a
                  href="merchan"
                  className="mt-8 block w-full rounded-md border border-transparent bg-white py-3 px-8 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
                >
                  En Savoir Plus
                </a>
              </div>
            </div>
          </section>

          <div>
            <div className="relative  py-10 sm:py-16 lg:py-26">
              <div className="mt-10 mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                {" "}
                <p
                  ref={ref38}
                  className={`font-serif    font-bold tracking-tight text-gray-700 sm:text-3xl ${
                    isVisible38
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Alors n’attendez plus, faites appel à notre expertise dès
                  maintenant !
                </p>
                <p
                  ref={ref212}
                  className={`font-serif    font-bold tracking-tight text-gray-700 sm:text-3xl ${
                    isVisible212
                      ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Parce que nous réinventons votre succès
                </p>
              </div>
            </div>{" "}
          </div>
        </main>
        <div>
          <footer
            className="bg-gradient-to-l from-zinc-600 to-gray-900"
            aria-labelledby="footer-heading"
          >
            <h2 id="footer-heading" className="sr-only">
              Footer
            </h2>
            <div className="mx-auto max-w-md px-6 pt-8 sm:max-w-7xl lg:px-8 lg:pt-16">
              <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                <div className="space-y-4 xl:col-span-1">
                  <img
                    className="h-24"
                    src="./images/lo.png"
                    alt="Company name"
                  />
                  <p className="text-base text-gray-400">
                    Des stratégies personnalisées et avant-gardistes, conçues
                    pour répondre à vos exigences.
                  </p>

                  <div className="flex space-x-3">
                    {footerNavigation.social.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">{item.name}</span>
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </a>
                    ))}
                  </div>
                  <dd className="flex text-base text-gray-400">
                    <PhoneIcon
                      className="h-6 w-6 flex-shrink-0  text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">+213 (550) 075-120</span>
                  </dd>
                  <dd className="flex text-base  text-gray-400">
                    <EnvelopeIcon
                      className="h-6 w-6 flex-shrink-0  text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-3">contact@ka-cpm.com</span>
                  </dd>
                  <p className="text-base text-gray-400">
                    Adresse : Bois des cars 01. Villa N°53 R.D.C Dely Ibrahim
                    Alger 16047
                  </p>
                </div>
                <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h3 className="text-base font-medium text-gray-200">
                        Nos Solutions
                      </h3>
                      <ul role="list" className="mt-4 space-y-4">
                        {footerNavigation.solutions.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className="text-base text-gray-400 hover:text-gray-200"
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mt-12 md:mt-0">
                      <h3 className="text-base font-medium text-gray-200">
                        Our company
                      </h3>
                      <ul role="list" className="mt-4 space-y-4">
                        {footerNavigation.support.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className="text-base text-gray-400 hover:text-gray-200"
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                      <h3 className="text-base font-medium text-gray-200">
                        Merchandising et PLV Pharmacie
                      </h3>
                      <ul role="list" className="mt-4 space-y-4">
                        {footerNavigation.company.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className="text-base text-gray-400 hover:text-gray-200"
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mt-12 md:mt-0">
                      <h3 className="text-base font-medium text-gray-200">
                        Contact
                      </h3>
                      <ul role="list" className="mt-4 space-y-4">
                        {footerNavigation.contact.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className="text-base text-gray-400 hover:text-gray-200"
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 border-t border-gray-200 py-8">
                <p className="text-base text-gray-400 xl:text-center">
                  &copy; 2023 KA Consulting & Pharmacy Management, Inc. All
                  rights reserved.
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}
