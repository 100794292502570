const posts = [
  {
    title: 'Notre Mission',
    href: '#',
    category: { name: 'Article', href: '#' },
    description:
      'Nous accompagnons les pharmacies d\'officine dans leur quête permanente de qualité et de  prospérité : ',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
    './images/img/mission.jpg',
    readingTime: '6 min',
    author: {
      name: 'Roel Aufderehar',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Notre Vision',
    href: '#',
    category: { name: 'Video', href: '#' },
    description:
      'devenir le partenaire de confiance et l’allié incontournable ...',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
    './images/img/vision.jpg',
    readingTime: '4 min',
    author: {
      name: 'Brenna Goyette',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Nos Valeurs',
    href: '#',
    category: { name: 'Case Study', href: '#' },
    description:
      'Professionnalisme ,Innovation , Intégrité ... ',
    datetime: '2020-02-12',
    imageUrl:
      './images/img/valeur.jpg',
    readingTime: '11 min',
    author: {
      name: 'Daniela Metz',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
]

export default function Example() {
  return (
    <div className="relative  px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28   bg-gradient-to-l from-zinc-600 to-teal-300">
        
      <div className="absolute inset-0">
        <div className="h-1/3   sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Notre identité de marque</h2>
          
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg  ">
              <div className="flex-shrink-0">
                <img className="object-cover object-center group-hover:opacity-75 h-48 w-full  " src={post.imageUrl}   alt="" />
                
              </div>
              <div className="flex flex-1 flex-col justify-between   p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-indigo-100">
                    
                  </p>
                  <a href={post.href} className="mt-2 block">
                    <p className="text-2xl font-semibold text-gray-100  text-center ">{post.title}</p>
                    <p className="mt-3 text-base text-gray-200">{post.description}</p>
                  </a>
                </div>
                <div className="mt-6 flex items-center">
                <div className="mt-10 flex items-right justify-right gap-x-6">
                <a
            
                  href="#"
                  className="flex items-center justify-center rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-gray-800 shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                 Savoir Plus
                </a>
               </div>
                 
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
