import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];

export default function NewAbouUsScreen() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const targetRef = useRef(null);

  const handleScroll = (event) => {
    event.preventDefault();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }

  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ref5 = useRef();
  const isVisible5 = useIsVisible(ref5);

  const ref6 = useRef();
  const isVisible6 = useIsVisible(ref6);

  const ref7 = useRef();
  const isVisible7 = useIsVisible(ref7);

  const ref37 = useRef();
  const isVisible37 = useIsVisible(ref37);

  const ref73 = useRef();
  const isVisible73 = useIsVisible(ref73);

  const ref74 = useRef();
  const isVisible74 = useIsVisible(ref74);

  const ref75 = useRef();
  const isVisible75 = useIsVisible(ref75);

  const ref76 = useRef();
  const isVisible76 = useIsVisible(ref76);

  return (
    <div>
      <div>
        <Helmet>
          <title>About Us</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <div>
        <Popover as="div" className="relative z-10">
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
            aria-label="Global"
          >
            <div className="flex w-full items-center justify-between lg:w-auto">
              <a href="/">
                <span className="sr-only">
                  KA Consulting And Pharmacy Management{" "}
                </span>
                <img className="h-28 w-auto " src="./images/lo.png" alt="" />
              </a>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-500 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>

            <div className="hidden   lg:flex lg:gap-x-12">
              <a href="/aboutus" className="text-sm font-semibold leading-6  ">
                Qui sommes-nous ?
              </a>
              <a
                href="/about"
                className="text-sm font-semibold leading-6 text-green-500 "
              >
                Notre identité de marque
              </a>
              <a href="/solution" className="text-sm font-semibold leading-6 ">
                Nos solutions
              </a>
              <a href="/merchan" className="text-sm font-semibold leading-6  ">
                Merchandising et PLV Pharmacie
              </a>
              <a
                href="https://ka-psul.com/"
                className="text-sm font-semibold leading-6  "
              >
                Ka-psul
              </a>
            </div>
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              <a
                href="/contact"
                className="rounded-md border border-transparent bg-teal-900 bg-opacity-60 py-2 px-6 text-base font-medium text-white hover:bg-opacity-20"
              >
                Contact
              </a>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
            >
              <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img className="h-8 w-auto" src="./images/lo.png" alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="mt-6 px-5">
                    <a
                      href="/contact"
                      className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <div></div>

        <div className="relative  px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28   ">
          <div className="absolute inset-0">
            <div className="h-1/3   sm:h-2/3" />
          </div>
          <div className="relative mx-auto max-w-7xl">
            <div className="text-center">
              <h2
                ref={ref7}
                className={`text-3xl font-bold tracking-tight text-white sm:text-4xl ${
                  isVisible7
                    ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                Notre identité de marque
              </h2>
            </div>
            <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
              <div
                ref={ref4}
                className={` flex flex-col overflow-hidden rounded-lg shadow-lg   hover:animate-wiggle hover:animate-infinite ${
                  isVisible4
                    ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                <div className="flex-shrink-0">
                  <img
                    className="object-cover object-center group-hover:opacity-75 h-48 w-full  "
                    src="./images/img/mission.jpg"
                    alt=""
                  />
                </div>
                <div className="flex flex-1 flex-col justify-between   p-6">
                  <div className="flex-1">
                    <p className="text  font-medium text-indigo-100"></p>

                    <p className="text-2xl font-semibold    text-center ">
                      Notre Mission
                    </p>
                    <p className="mt-3 text-base text   ">
                      Nous accompagnons les pharmacies d'officine dans leur
                      quête permanente de qualité et de prospérité ...{" "}
                    </p>
                  </div>
                  <div className="mt-6 flex items-center justify-center">
                    <div className="  flex items-right justify-right gap-x-6">
                      <a
                        href="/mission"
                        className="flex items-center justify-center rounded-md  bg-teal-400 px-3.5 py-1.5 text-base font-semibold leading-7  shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 text  focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        En Savoir Plus
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={ref5}
                className={` flex flex-col overflow-hidden rounded-lg shadow-lg   hover:animate-wiggle hover:animate-infinite ${
                  isVisible5
                    ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                <div className="flex-shrink-0">
                  <img
                    className="object-cover object-center group-hover:opacity-75 h-48 w-full  "
                    src="./images/img/vision.jpg"
                    alt=""
                  />
                </div>
                <div className="flex flex-1 flex-col justify-between   p-6">
                  <div className="flex-1">
                    <p className="text font-medium text-indigo-100"></p>

                    <p className="text-2xl font-semibold    text-center ">
                      Notre Vision
                    </p>
                    <p className="mt-3 text-base text  ">
                      Devenir le partenaire de confiance et l’allié
                      incontournable ...
                    </p>
                  </div>
                  <div className="mt-6 flex items-center justify-center ">
                    <div className=" flex items-right justify-right gap-x-6">
                      <a
                        href="/vision"
                        className="flex items-center justify-center rounded-md   px-3.5 py-1.5 text-base text font-semibold leading-7  bg-teal-400 shadow-sm hover:bg-white-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        En Savoir Plus
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                ref={ref6}
                className={`    flex-col overflow-hidden rounded-lg shadow-lg   hover:animate-wiggle hover:animate-infinite ${
                  isVisible6
                    ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                <div className="flex-shrink-0">
                  <img
                    className="object-cover object-center group-hover:opacity-75 h-48 w-full  "
                    src="./images/img/valeur.jpg"
                    alt=""
                  />
                </div>
                <div className="flex flex-1 flex-col justify-between   p-6">
                  <div className="flex-1 ">
                    <p className="text font-medium text-indigo-100"></p>

                    <p className="text-2xl font-semibold    text-center ">
                      Nos Valeurs
                    </p>
                    <p className="mt-3 text-base text ">
                      Professionnalisme ,Innovation , Intégrité ...{" "}
                    </p>
                  </div>
                  <div className="mt-6 flex items-center justify-center ">
                    <div className="mt-10 flex items-right justify-right gap-x-6">
                      <a
                        href="/valeur"
                        className="flex items-center justify-center rounded-md bg-teal-400 px-3.5 py-1.5 text-base font-semibold leading-7   shadow-sm hover:bg-teal-300 focus-visible:outline focus-visible:outline-2 text  focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        En Savoir Plus
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
              {" "}
              <p
                ref={ref37}
                className={`font-serif    font-bold tracking-tight   sm:text-xl ${
                  isVisible37
                    ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                Alors n’attendez plus, faites appel à notre expertise dès
                maintenant !
              </p>
              <p
                ref={ref37}
                className={`font-serif    font-bold tracking-tight   sm:text-xl ${
                  isVisible37
                    ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                Parce que nous réinventons votre succès
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
