import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];

export default function ValeurScreen() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const targetRef = useRef(null);

  const handleScroll = (event) => {
    event.preventDefault();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }

  const ref52 = useRef();
  const isVisible52 = useIsVisible(ref52);

  const ref53 = useRef();
  const isVisible53 = useIsVisible(ref53);

  const ref54 = useRef();
  const isVisible54 = useIsVisible(ref54);

  const ref55 = useRef();
  const isVisible55 = useIsVisible(ref55);

  const ref56 = useRef();
  const isVisible56 = useIsVisible(ref56);

  const ref57 = useRef();
  const isVisible57 = useIsVisible(ref57);

  const ref58 = useRef();
  const isVisible58 = useIsVisible(ref58);

  const ref59 = useRef();
  const isVisible59 = useIsVisible(ref59);

  return (
    <div>
      <div>
        <Helmet>
          <title>Nos Valeurs</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <div>
        <Popover as="div" className="relative z-10">
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
            aria-label="Global"
          >
            <div className="flex w-full items-center justify-between lg:w-auto">
              <a href="/">
                <span className="sr-only">
                  KA Consulting And Pharmacy Management{" "}
                </span>
                <img className="h-28 w-auto " src="./images/lo.png" alt="" />
              </a>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-500 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>

            <div className="hidden   lg:flex lg:gap-x-12">
              <a href="/aboutus" className="text-sm font-semibold leading-6  ">
                Qui sommes-nous ?
              </a>
              <a href="/about" className="text-sm font-semibold leading-6 ">
                Notre identité de marque
              </a>
              <a href="/solution" className="text-sm font-semibold leading-6">
                Nos solutions
              </a>
              <a href="/merchan" className="text-sm font-semibold leading-6  ">
                Merchandising et PLV Pharmacie
              </a>
              <a
                href="https://ka-psul.com/"
                className="text-sm font-semibold leading-6  "
              >
                Ka-psul
              </a>
            </div>
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              <a
                href="/contact"
                className="rounded-md border border-transparent bg-teal-900 bg-opacity-60 py-2 px-6 text-base font-medium text-white hover:bg-opacity-20"
              >
                Contact
              </a>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
            >
              <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img className="h-8 w-auto" src="./images/lo.png" alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="mt-6 px-5">
                    <a
                      href="/contact"
                      className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <div></div>

        <section
          aria-labelledby="social-impact-heading"
          className="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8"
        >
          <div className="relative overflow-hidden rounded-lg bg-gradient-to-l from-zinc-600 to-teal-300">
            <div className="relative bg-gray-900 bg-opacity-60 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
              <div className="relative mx-auto flex max-w-3xl flex-col items-center  ">
                <h2
                  id="social-impact-heading"
                  className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
                >
                  <span
                    ref={ref52}
                    className={`block sm:inline ${
                      isVisible52
                        ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                        : "opacity-0 invisible"
                    }`}
                  >
                    {" "}
                    Nos Valeurs{" "}
                  </span>
                </h2>
                <div
                  ref={ref53}
                  className={`mt-10  text-white text-xl     ${
                    isVisible53
                      ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                      : "opacity-0 invisible"
                  }`}
                >
                  <div
                    ref={ref54}
                    className={`block sm:inline ${
                      isVisible54
                        ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                        : "opacity-0 invisible"
                    }`}
                  >
                    <p className="mt-2 text-2xl  leading-8 tracking-tight text-teal-200  sm:text-2xl">
                      Professionnalisme
                    </p>
                    <p>
                      Nous attachons une grande importance à l'excellence
                      professionnelle dans tout ce que nous faisons. Nous
                      mettons en avant notre expertise et notre savoir-faire
                      pour fournir des conseils et des solutions de haute
                      qualité à nos clients.
                    </p>
                  </div>

                  <div
                    ref={ref55}
                    className={`block sm:inline ${
                      isVisible55
                        ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                        : "opacity-0 invisible"
                    }`}
                  >
                    <p className="mt-2 text-2xl  leading-8 tracking-tight text-teal-200 sm:text-2xl">
                      Innovation
                    </p>

                    <p>
                      Nous croyons fermement à l'importance de l'innovation pour
                      rester compétitifs et relevons constamment de nouveaux
                      défis. Nous nous efforçons d'identifier et de mettre en
                      œuvre des approches innovantes pour aider les pharmacies
                      d'officine à se démarquer de la concurrence.
                    </p>
                  </div>

                  <div
                    ref={ref56}
                    className={`block sm:inline ${
                      isVisible56
                        ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                        : "opacity-0 invisible"
                    }`}
                  >
                    <p className="mt-2 text-2xl  leading-8 tracking-tight text-teal-200 sm:text-2xl">
                      Orienté client
                    </p>

                    <p>
                      Nous plaçons les besoins de nos clients au cœur de nos
                      préoccupations. Nous écoutons attentivement leurs
                      demandes, comprenons leurs objectifs spécifiques et nous
                      nous mobilisons pour leur offrir des solutions sur mesure
                      qui répondent à leurs besoins uniques .
                    </p>
                  </div>

                  <div
                    ref={ref57}
                    className={`block sm:inline ${
                      isVisible57
                        ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                        : "opacity-0 invisible"
                    }`}
                  >
                    <p className="mt-2 text-2xl   leading-8 tracking-tight text-teal-200 sm:text-2xl">
                      Intégrité
                    </p>

                    <p>
                      Nous nous engageons à agir avec intégrité, transparence et
                      éthique dans toutes nos interactions. Nous traitons nos
                      clients, nos partenaires et nos employés avec respect,
                      honnêteté et équité.
                    </p>
                    <p className="mt-2 text-2xl   leading-8 tracking-tight text-teal-200 sm:text-2xl">
                      Collaboration
                    </p>

                    <p>
                      Nous croyons au pouvoir de la collaboration et à la valeur
                      de travailler en équipe. Nous favorisons un environnement
                      de travail collégial et encourageons la coopération entre
                      collègues, afin de créer des synergies et d'apporter des
                      solutions complètes à nos clients .
                    </p>
                  </div>

                  <p className="mt-2">
                    Ces valeurs fondamentales guident notre approche de travail
                    et nous aident à fournir des services de qualité supérieure
                    .{" "}
                  </p>
                  <p className="font-bold text-center text-xl text-teal-600 mt-2 ">
                    Exploitez nos atouts pour relever vos défis avec succès !!
                  </p>
                </div>
              </div>
              <div className="prose prose-indigo mx-auto mt-2 text-gray-100 lg:col-start-1 lg:row-start-1 lg:max-w-none"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
