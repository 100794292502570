import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import React, { useState } from "react";
import HomeScreen from "./Screen/HomeScreen";
import "./index.css";
import Contact from "./Screen/Contact";
import Sol1Screen from "./Screen/Sol1Screen";
import Sol2Screen from "./Screen/Sol2Screen";
import Sol3Screen from "./Screen/Sol3Screen";
import SolutionScreen from "./Screen/SolutionScreen";
import MerchanScreen from "./Screen/MerchanScreen";
import LoginScreen from "./Screen/LoginScreen";
import EssaiScreen from "./Screen/EssaiScreen";
import AbousUsScreen from "./Screen/AboutUsScreen";
import Sommes1 from "./Screen/Sommes1";
import Sommes2 from "./Screen/Sommes2";
import Sommes3 from "./Screen/Sommes3";
import Sommes4 from "./Screen/Sommes4";
import VissionScreen from "./Screen/VissionScreen";
import MissionScreen from "./Screen/MissionScreen";
import NewAbouUsScreen from "./Screen/NewAboutUs";
import ValeurScreen from "./Screen/ValeurScreen";
import ReactGA from "react-ga";
function App() {
  const TRACKIND_ID = "G-CKZLE0FG4L";
  ReactGA.initialize(TRACKIND_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  const whatsappNumber = "+213550075120";

  return (
    <div>
      <BrowserRouter>
        <div>
          <main>
            <a
              href={`https://wa.me/${whatsappNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              className="fixed bottom-8 right-8 rounded-full bg-green-400 text-white w-14 h-14 flex items-center justify-center cursor-pointer shadow-2xl z-50"
            >
              <img
                src="./images/what.png"
                alt="WhatsApp Icon"
                className="h-14 w-14"
              />
            </a>
            <Routes>
              <Route path="/contact" element={<Contact />} />
              <Route path="/sol1" element={<Sol1Screen />} />
              <Route path="/sol2" element={<Sol2Screen />} />
              <Route path="/sol3" element={<Sol3Screen />} />
              <Route path="/essai" element={<EssaiScreen />} />
              <Route path="/solution" element={<SolutionScreen />} />
              <Route path="/adminkaadmin" element={<LoginScreen />} />
              <Route path="/merchan" element={<MerchanScreen />} />
              <Route path="/aboutus" element={<AbousUsScreen />} />
              <Route path="/sone" element={<Sommes1 />} />
              <Route path="/stwo" element={<Sommes2 />} />
              <Route path="/sthree" element={<Sommes3 />} />
              <Route path="/sfort" element={<Sommes4 />} />
              <Route path="/about" element={<NewAbouUsScreen />} />
              <Route path="/mission" element={<MissionScreen />} />
              <Route path="/vision" element={<VissionScreen />} />
              <Route path="/valeur" element={<ValeurScreen />} />
              <Route path="/" element={<HomeScreen />} />
            </Routes>{" "}
          </main>
        </div>
      </BrowserRouter>
    </div>
  );
}
export default App;
