import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];
export default function Sol3Screen() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const targetRef = useRef(null);

  const handleScroll = (event) => {
    event.preventDefault();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }

  const ref52 = useRef();
  const isVisible52 = useIsVisible(ref52);

  const ref53 = useRef();
  const isVisible53 = useIsVisible(ref53);

  return (
    <div className="overflow-hidden bg-white">
      <div>
        <Helmet>
          <title>Nos Solutions Pour Votre Client</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <Popover as="div" className="relative z-10">
        <nav
          className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
          aria-label="Global"
        >
          <div className="flex w-full items-center justify-between lg:w-auto">
            <a href="/">
              <span className="sr-only">
                KA Consulting And Pharmacy Management{" "}
              </span>
              <img className="h-28 w-auto " src="./images/lo.png" alt="" />
            </a>
            <div className="-mr-2 flex items-center lg:hidden">
              <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-500 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                <span className="sr-only">Menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>

          <div className="hidden   lg:flex lg:gap-x-12">
            <a href="/aboutus" className="text-sm font-semibold leading-6  ">
              Qui sommes-nous ?
            </a>
            <a href="/about" className="text-sm font-semibold leading-6 ">
              Notre identité de marque
            </a>
            <a href="/solution" className="text-sm font-semibold leading-6">
              Nos solutions
            </a>
            <a href="/merchan" className="text-sm font-semibold leading-6  ">
              Merchandising et PLV Pharmacie
            </a>
            <a
              href="https://ka-psul.com/"
              className="text-sm font-semibold leading-6  "
            >
              Ka-psul
            </a>
          </div>
          <div className="hidden lg:flex lg:items-center lg:space-x-6">
            <a
              href="/contact"
              className="rounded-md border border-transparent bg-teal-900 bg-opacity-60 py-2 px-6 text-base font-medium text-white hover:bg-opacity-30"
            >
              Contact
            </a>
          </div>
        </nav>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img className="h-8 w-auto" src="./images/lo.png" alt="" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="space-y-1 px-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a
                    href="/contact"
                    className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <div className="isolate bg-white"></div>

      <section
        aria-labelledby="social-impact-heading"
        className="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8"
      >
        <div className="relative overflow-hidden rounded-lg bg-gradient-to-l from-zinc-600 to-teal-300">
          <div className="relative bg-gray-900 bg-opacity-60 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
            <div className="relative mx-auto flex max-w-3xl flex-col items-center  ">
              <h2
                id="social-impact-heading"
                className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
              >
                <span
                  ref={ref52}
                  className={`block sm:inline ${
                    isVisible52
                      ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                      : "opacity-0 invisible"
                  }`}
                >
                  {" "}
                  Nos Solutions Pour Vos Patients
                </span>
                <h2 className="text-lg text-center mt-3 font-semibold text-teal-200">
                  Laissez vos patients parler pour vous ! 
                </h2>
              </h2>

              <div
                ref={ref53}
                className={`mt-10  text-white     ${
                  isVisible53
                    ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                    : "opacity-0 invisible"
                }`}
              >
                <div className="mx-auto max-w-prose text-base lg:max-w-none">
                  <p className="text-lg text-white mt-2">
                    L’avènement d’internet dans la sphère médicale, avec une
                    approche centrée autour du patient, change votre relation
                    avec lui en tant que pharmacien et acteur de santé. Plus
                    informé, le patient échange plus activement avec son
                    praticien sur les médicaments qu’il lui délivre, sur
                    l’observance de son traitement et plus largement sur
                    l’accompagnement dans la prise en charge de sa pathologie.
                  </p>
                </div>
                <div className="prose prose-indigo mx-auto   text-white lg:col-start-1 lg:row-start-1 lg:max-w-none">
                  <p>
                    Ces informations médicales confèrent au patient un nouveau
                    POUVOIR favorisant son autonomie et modifient la relation
                    qu’il entretient avec son pharmacien (Durand Salmon et Le
                    Tallec, 2014).
                  </p>
                  <p className="mt-2">
                    Comme titulaire d’officine, vous devez vous attacher à
                    SATISFAIRE et à FIDELISER votre patientèle (en regard de la
                    réglementation et des lois en vigueur concernant l’exercice
                    de la pharmacie d’officine). Ces deux éléments sont
                    importants pour vous, tandis que le patient est libre dans
                    son choix d’officine.
                  </p>
                  <p className="mt-2">
                    La fidélité au point de vente qu’est l’officine est un
                    élément fort et au cœur de la réflexion managériale des
                    pharmaciens titulaires de pharmacies (Moinier, 2009)
                  </p>
                  <p className="mt-2">
                    Mais si le patient est satisfait de sa relation avec vous,
                    il réalisera ses achats de produits pharmaceutiques dans
                    votre officine et sera enclin à les réitérer chez vous à
                    chaque besoin.
                  </p>
                  <p>
                    Chez KA Consulting & Pharmacy Management, nos solutions ont
                    été développées de manière à renforcer et consolider votre
                    relation avec vos clients, qui satisfaits, véhiculeraient
                    une image positive de votre pharmacie et deviendraient à
                    leur tour des prescripteurs de vos excellents services en
                    recommandant votre officine auprès d’autres clients.
                  </p>
                  <p className="font-bold text-center text-teal-200 ">
                    Un service client sur mesure pour une expérience inoubliable
                    !
                  </p>{" "}
                  <p>
                    Grâce à nos méthodes et outils fondés sur les principes du
                    Neuro-marketing et basés sur la connaissance de vos clients
                    et la compréhension profonde de leurs besoins, vous saurez
                    calibrer vos offres et ajuster vos services afin de
                    satisfaire vos clients. Nous vous accompagnons à fidéliser
                    votre patientèle en lui offrant la meilleure expérience
                    possible au sein de votre officine, ce qui se traduira par
                    une augmentation significative de vos ventes et de ce fait,
                    une croissance de la performance économique de votre
                    pharmacie officine.
                  </p>
                  <p className="font-bold text-xl text-center text-teal-200 mt-2">
                    Alors n’attendez plus, faites appel à notre expertise dès
                    maintenant !
                  </p>{" "}
                  <p className="font-bold text-xl text-center text-teal-200 mt-2">
                    Parce que nous réinventons votre succès
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
