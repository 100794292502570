import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];

export default function Sol2Screen() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const targetRef = useRef(null);

  const handleScroll = (event) => {
    event.preventDefault();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }

  const ref52 = useRef();
  const isVisible52 = useIsVisible(ref52);

  const ref53 = useRef();
  const isVisible53 = useIsVisible(ref53);

  const ref54 = useRef();
  const isVisible54 = useIsVisible(ref54);

  const ref55 = useRef();
  const isVisible55 = useIsVisible(ref55);

  const ref56 = useRef();
  const isVisible56 = useIsVisible(ref56);

  const ref57 = useRef();
  const isVisible57 = useIsVisible(ref57);

  const ref58 = useRef();
  const isVisible58 = useIsVisible(ref58);

  const ref59 = useRef();
  const isVisible59 = useIsVisible(ref59);
  return (
    <div className="overflow-hidden bg-white">
      <div>
        <Helmet>
          <title>Nos Solutions Pour Votre Equipe Officinale</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <Popover as="div" className="relative z-10">
        <nav
          className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
          aria-label="Global"
        >
          <div className="flex w-full items-center justify-between lg:w-auto">
            <a href="/">
              <span className="sr-only">
                KA Consulting And Pharmacy Management{" "}
              </span>
              <img className="h-28 w-auto " src="./images/lo.png" alt="" />
            </a>
            <div className="-mr-2 flex items-center lg:hidden">
              <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-500 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                <span className="sr-only">Menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>

          <div className="hidden   lg:flex lg:gap-x-12">
            <a href="/aboutus" className="text-sm font-semibold leading-6  ">
              Qui sommes-nous ?
            </a>
            <a href="/about" className="text-sm font-semibold leading-6 ">
              Notre identité de marque
            </a>
            <a href="/solution" className="text-sm font-semibold leading-6">
              Nos solutions
            </a>
            <a href="/merchan" className="text-sm font-semibold leading-6  ">
              Merchandising et PLV Pharmacie
            </a>
            <a
              href="https://ka-psul.com/"
              className="text-sm font-semibold leading-6  "
            >
              Ka-psul
            </a>
          </div>
          <div className="hidden lg:flex lg:items-center lg:space-x-6">
            <a
              href="/contact"
              className="rounded-md border border-transparent bg-teal-900 bg-opacity-60 py-2 px-6 text-base font-medium text-white hover:bg-opacity-20"
            >
              Contact
            </a>
          </div>
        </nav>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img className="h-8 w-auto" src="./images/lo.png" alt="" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="space-y-1 px-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a
                    href="/contact"
                    className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <div className="isolate bg-white"></div>

      <section
        aria-labelledby="social-impact-heading"
        className="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8"
      >
        <div className="relative overflow-hidden rounded-lg    bg-gradient-to-l from-zinc-600 to-teal-300">
          <div className="relative bg-gray-900 bg-opacity-60 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
            <div className="relative mx-auto flex max-w-3xl flex-col items-center">
              <h2
                id="social-impact-heading"
                className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
              >
                <span
                  ref={ref52}
                  className={`block sm:inline ${
                    isVisible52
                      ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                      : "opacity-0 invisible"
                  }`}
                >
                  {" "}
                  Nos Solutions Pour Votre Equipe Officinale{" "}
                </span>
              </h2>
              <div
                ref={ref53}
                className={`mt-10  text-white   ${
                  isVisible53
                    ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                    : "opacity-0 invisible"
                }`}
              >
                <div className="mx-auto max-w-prose text-base lg:max-w-none">
                  <p className="text-lg text-white mt-2">
                    L’implication de votre personnel est l’un des éléments clés
                    du succès de votre pharmacie d’officine. Selon une étude
                    IPSOS de 2018, « le collaboratif a un impact
                    particulièrement positif sur […] la productivité des équipes
                    (65%) ».
                  </p>
                  <h2 className="text-lg font-semibold text-teal-200 text-center  ">
                    La Digitalisation : Un levier puissant pour stimuler le
                    sentiment d’appartenance
                  </h2>
                </div>
                <div className="prose prose-indigo mx-auto text-white lg:col-start-1 lg:row-start-1 lg:max-w-none">
                  <p>
                    Cette performance s’explique aussi, en grande partie, par
                    l’amélioration de l’engagement des employés en intégrant des
                    outils digitaux qui révolutionnent, en effet, la
                    communication au sein des entreprises. L’information circule
                    mieux et plus vite, assurant une gestion efficace des
                    tâches.
                  </p>
                  <p className="mt-2">
                    Notre approche a été développée de manière à créer une
                    culture de responsabilisation et de qualité, en créant un
                    environnement de travail collaboratif et inclusif visant à
                    renforcer l’esprit de vos équipes, stimuler leur innovation
                    à travers l’échange des idées, et ainsi fournir des
                    solutions concrètes aux défis auxquels est confrontée votre
                    pharmacie d’officine.
                  </p>
                  <p className="mt-2">
                    Les solutions collaboratives que nous proposons offrent
                    également un suivi précis des progrès, une attribution
                    claire des responsabilités et une coordination optimale des
                    efforts.
                  </p>

                  <p className="font-bold text-center text-teal-200 ">
                    Que faire, Qui fait Quoi, Comment le faire et Quand le faire
                  </p>
                  <p>
                    Tous vos employés ne sont pas nécessairement en mesure de se
                    transformer et de suivre la transformation et l’évolution de
                    votre pharmacie. Pour cela, Nous vous accompagnons dans le
                    renforcement et l’amélioration de leurs aptitudes en leur
                    donnant les clefs de l’action dans le cadre de leur
                    expertise, par le biais de formations et d’ateliers
                    pratiques adaptés pour vos équipes, inspirés du Design
                    Thinking et propices à la créativité, dans les domaines de
                    l’accueil client, la maitrise des techniques de vente, le
                    management de la communication, le merchandising et le
                    marketing stratégique, mais aussi nous les accompagnons afin
                    d’acquérir de nouvelles compétences en matière de gestion
                    opérationnelle, ce qui se traduira par une amélioration
                    globale de la performance de votre pharmacie d’officine et
                    de sa rentabilité.
                  </p>
                  <p className="mt-2">
                    Nous vous aidons à bâtir une culture d’entreprise qui soit
                    centrée sur votre client et à laquelle vos employés et
                    collaborateurs seront alignés.
                  </p>

                  <p className="font-bold text-xl text-center  text-teal-200 mt-2">
                    Alors n’attendez plus, faites appel à notre expertise dès
                    maintenant !
                  </p>
                  <p className="font-bold text-xl text-center text-teal-200 mt-2">
                    Parce que nous réinventons votre succès
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
