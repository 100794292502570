import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];

export default function Sommes2() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const targetRef = useRef(null);

  const handleScroll = (event) => {
    event.preventDefault();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }

  const ref52 = useRef();
  const isVisible52 = useIsVisible(ref52);

  const ref53 = useRef();
  const isVisible53 = useIsVisible(ref53);

  return (
    <div>
      <div>
        <Helmet>
          <title>Et si nos solutions devenaient les vôtres !</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <div>
        <Popover as="div" className="relative z-10">
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
            aria-label="Global"
          >
            <div className="flex w-full items-center justify-between lg:w-auto">
              <a href="/">
                <span className="sr-only">
                  KA Consulting And Pharmacy Management{" "}
                </span>
                <img className="h-28 w-auto " src="./images/lo.png" alt="" />
              </a>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-500 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>

            <div className="hidden   lg:flex lg:gap-x-12">
              <a href="/aboutus" className="text-sm font-semibold leading-6  ">
                Qui sommes-nous ?
              </a>
              <a href="/about" className="text-sm font-semibold leading-6 ">
                Notre identité de marque
              </a>
              <a href="/solution" className="text-sm font-semibold leading-6">
                Nos solutions
              </a>
              <a href="/merchan" className="text-sm font-semibold leading-6  ">
                Merchandising et PLV Pharmacie
              </a>
              <a
                href="https://ka-psul.com/"
                className="text-sm font-semibold leading-6  "
              >
                Ka-psul
              </a>
            </div>
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              <a
                href="/contact"
                className="rounded-md border border-transparent bg-teal-900 bg-opacity-60 py-2 px-6 text-base font-medium text-white hover:bg-opacity-20"
              >
                Contact
              </a>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
            >
              <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img className="h-8 w-auto" src="./images/lo.png" alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="mt-6 px-5">
                    <a
                      href="/contact"
                      className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <div></div>

        <section
          aria-labelledby="social-impact-heading"
          className="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8"
        >
          <div className="relative overflow-hidden rounded-lg bg-gradient-to-l from-zinc-600 to-teal-300">
            <div className="relative bg-gray-900 bg-opacity-60 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
              <div className="relative mx-auto flex max-w-3xl flex-col items-center  ">
                <h2
                  id="social-impact-heading"
                  className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
                >
                  <span
                    ref={ref52}
                    className={`block sm:inline ${
                      isVisible52
                        ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                        : "opacity-0 invisible"
                    }`}
                  >
                    {" "}
                    Et si nos solutions devenaient les vôtres !{" "}
                  </span>
                </h2>
                <p
                  ref={ref53}
                  className={`mt-10 text-xl text-white     ${
                    isVisible53
                      ? "animate-fade-up   animate-duration-[1000ms] animate-delay-700"
                      : "opacity-0 invisible"
                  }`}
                >
                  Nous sommes une entreprise de consulting et de conseil
                  spécialisée dans l&#39;accompagnement des pharmacies
                  d&#39;officine dans leur développement et leur croissance,
                  ainsi que dans l’assistance des pharmaciens à s’installer en
                  officine, par l’application d’approches de gestion
                  stratégiques axées sur la qualité et l’excellence
                  opérationnelle qui permettent d’améliorer la performance de
                  votre pharmacie, d’améliorer la qualité de vos services et de
                  votre organisation et par conséquent d’accroître votre
                  rentabilité en prônant la qualité, la collaboration,
                  l’adaptabilité et la recherche permanente d’optimisation et
                  d’amélioration continue. Chez KA Consulting &amp; Pharmacy
                  Management, nous sommes convaincus qu’une pharmacie d’officine
                  est une entreprise à part entière avec toutes les compétences
                  et les ressources nécessaires pour transformer son activité en
                  une véritable entreprise performante et en phase avec son
                  temps. Et pour cela, votre pharmacie doit être en mesure de
                  développer son activité de manière pérenne grâce à une
                  stratégie ciblée et adaptée. Notre équipe de consultants est
                  composée de pharmacien(nes) alliant une expertise approfondie
                  et une expérience de plus de 17 ans dans le secteur
                  pharmaceutique ainsi que dans la Gestion d’Entreprises, le
                  Management de Projets et le Neuro-marketing.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
