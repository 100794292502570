import { Fragment, useEffect, useRef, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];

export default function AbousUsScreen() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const targetRef = useRef(null);

  const handleScroll = (event) => {
    event.preventDefault();
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersecting(entry.isIntersecting);
      });

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref]);

    return isIntersecting;
  }
  const ref89 = useRef();
  const isVisible89 = useIsVisible(ref89);

  const ref87 = useRef();
  const isVisible87 = useIsVisible(ref87);

  const ref86 = useRef();
  const isVisible86 = useIsVisible(ref86);

  const ref88 = useRef();
  const isVisible88 = useIsVisible(ref88);

  const ref85 = useRef();
  const isVisible85 = useIsVisible(ref85);

  const ref84 = useRef();
  const isVisible84 = useIsVisible(ref84);

  const ref83 = useRef();
  const isVisible83 = useIsVisible(ref83);

  const ref82 = useRef();
  const isVisible82 = useIsVisible(ref82);

  const ref81 = useRef();
  const isVisible81 = useIsVisible(ref81);

  const ref80 = useRef();
  const isVisible80 = useIsVisible(ref80);

  return (
    <div>
      <div>
        <Helmet>
          <title>Nos Solutions</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <div>
        <Popover as="div" className="relative z-10">
          <nav
            className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
            aria-label="Global"
          >
            <div className="flex w-full items-center justify-between lg:w-auto">
              <a href="/">
                <span className="sr-only">
                  KA Consulting And Pharmacy Management{" "}
                </span>
                <img className="h-28 w-auto " src="./images/lo.png" alt="" />
              </a>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-500 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>

            <div className="hidden   lg:flex lg:gap-x-12">
              <a href="/aboutus" className="text-sm font-semibold leading-6  ">
                Qui sommes-nous ?
              </a>
              <a href="/about" className="text-sm font-semibold leading-6 ">
                Notre identité de marque
              </a>
              <a
                href="/solution"
                className="text-sm font-semibold leading-6 text-green-500"
              >
                Nos solutions
              </a>
              <a href="/merchan" className="text-sm font-semibold leading-6  ">
                Merchandising et PLV Pharmacie
              </a>
              <a
                href="https://ka-psul.com/"
                className="text-sm font-semibold leading-6  "
              >
                Ka-psul
              </a>
            </div>
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              <a
                href="/contact"
                className="rounded-md border border-transparent bg-teal-900 bg-opacity-60 py-2 px-6 text-base font-medium text-white hover:bg-opacity-20"
              >
                Contact
              </a>
            </div>
          </nav>

          <Transition
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
            >
              <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="flex items-center justify-between px-5 pt-4">
                  <div>
                    <img className="h-8 w-auto" src="./images/lo.png" alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="pt-5 pb-6">
                  <div className="space-y-1 px-2">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="mt-6 px-5">
                    <a
                      href="/contact"
                      className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <div>
          <div className="relative  py-16 sm:py-24 lg:py-32">
            <div className="relative">
              <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                <p
                  ref={ref80}
                  className={`font-serif  text-4xl font-bold tracking-tight text-gray-700 sm:text-5xl ${
                    isVisible80
                      ? "animate-fade-right animate-duration-800 animate-delay-200 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  Nos Solutions{" "}
                </p>
              </div>
              <div className="mx-auto mt-12 grid max-w-md gap-8 px-6 sm:max-w-lg lg:max-w-7xl lg:grid-cols-3 lg:px-8">
                <a
                  href="/sol1"
                  ref={ref81}
                  className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2  hover:animate-wiggle hover:animate-infinite ${
                    isVisible81
                      ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <img
                    src="./images/img/9.jpg"
                    alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                    className="object-cover object-center group-hover:opacity-75"
                  />
                  <div
                    className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                    aria-hidden="true"
                  />
                  <div
                    aria-hidden="true"
                    className="bg-gradient-to-b from-transparent to-black opacity-50"
                  />
                  <div className="flex items-end p-6">
                    <div
                      ref={ref82}
                      className={`    ${
                        isVisible82
                          ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      <h3 className="font-semibold text-white">
                        <a href="#">
                          <span className="absolute inset-0" />
                          Nos Solutions Pour Votre Organisation
                        </a>
                      </h3>

                      <p aria-hidden="true" className="mt-1 text-sm text-white">
                        Á moindre effort pour un grand bénéfice !
                      </p>
                    </div>
                  </div>
                </a>

                <a
                  href="/sol2"
                  ref={ref83}
                  className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2 hover:animate-wiggle hover:animate-infinite   ${
                    isVisible83
                      ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <img
                    src="./images/img/8.jpg"
                    alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                    className="object-cover object-left group-hover:opacity-75"
                  />
                  <div
                    className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                    aria-hidden="true"
                  />
                  <div
                    aria-hidden="true"
                    className="bg-gradient-to-b from-transparent to-black opacity-50"
                  />
                  <div className="flex items-end p-6">
                    <div
                      ref={ref84}
                      className={`    ${
                        isVisible84
                          ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      <h3 className="font-semibold text-white">
                        <a href="#">
                          <span className="absolute inset-0" />
                          Nos Solutions Pour Votre Equipe Officinale
                        </a>
                      </h3>

                      <p aria-hidden="true" className="mt-1 text-sm text-white">
                        {" "}
                        Votre personnel est l’image de votre pharmacie et une
                        pièce clé de son succès !
                      </p>
                    </div>
                  </div>
                </a>

                <a
                  href="/sol3"
                  ref={ref85}
                  className={`  group aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2  hover:animate-wiggle hover:animate-infinite ${
                    isVisible85
                      ? "animate-fade-right animate-duration-1000 animate-delay-600 animate-ease-in-out  "
                      : "opacity-0 invisible   "
                  }`}
                >
                  <img
                    src="./images/img/16.jpg"
                    alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
                    className="object-cover object-center group-hover:opacity-75"
                  />
                  <div
                    className="absolute inset-0 bg-gradient-to-l from-gray-200 to-gray-500 mix-blend-multiply"
                    aria-hidden="true"
                  />
                  <div
                    aria-hidden="true"
                    className="bg-gradient-to-b from-transparent to-black opacity-50"
                  />
                  <div className="flex items-end p-6">
                    <div
                      ref={ref86}
                      className={`    ${
                        isVisible86
                          ? "animate-fade-right animate-duration-1000 animate-delay-700 animate-ease-in-out  "
                          : "opacity-0 invisible   "
                      }`}
                    >
                      <h3 className="font-semibold text-white">
                        <a href="#">
                          <span className="absolute inset-0" />
                          Nos Solutions Pour Vos Patients
                        </a>
                      </h3>

                      <p aria-hidden="true" className="mt-1 text-sm text-white">
                        Laissez vos patients parler pour vous !
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="mt-10 mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
              {" "}
              <p
                ref={ref87}
                className={`font-serif    font-bold tracking-tight   sm:text-xl ${
                  isVisible87
                    ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                Alors n’attendez plus, faites appel à notre expertise dès
                maintenant !
              </p>
              <p
                ref={ref87}
                className={`font-serif    font-bold tracking-tight   sm:text-xl ${
                  isVisible87
                    ? "animate-fade-up animate-duration-800 animate-delay-200 animate-ease-in-out  "
                    : "opacity-0 invisible   "
                }`}
              >
                Parce que nous réinventons votre succès
              </p>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}
