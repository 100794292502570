import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { collection, getDocs, onSnapshot, orderBy } from "firebase/firestore";
import { query } from "firebase/database";

export default function AdminScreen() {
  const [formulaire, setForms] = useState([]);
  const formu = collection(db, "formulaire");

  useEffect(() => {
    console.log("Effect ran"); // Ajout d'une instruction console.log pour vérifier si l'effet se déclenche

    const queryMessages = query(formu);
    const unsubscribe = onSnapshot(queryMessages, (snapshot) => {
      let formulaire = [];
      snapshot.forEach((doc) => {
        formulaire.push({ ...doc.data(), id: doc.id });
      });
      setForms(formulaire);

      console.log("Forms updated:", formulaire); // Ajout d'une instruction console.log pour vérifier les nouvelles valeurs de `forms`
    });

    return () => {
      console.log("Unsubscribed"); // Ajout d'une instruction console.log pour vérifier si la désinscription se produit
      unsubscribe();
    };
  }, []);

  return (
    <main className="bg-white px-4 pt-16 pb-24 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
      <div className="mx-auto max-w-3xl">
        <div className="max-w-xl">
          <p className="mt-2 text-4xl font-bold text-teal-600 tracking-tight">
            Message
          </p>
        </div>

        <section
          aria-labelledby="order-heading"
          className="mt-10 border-t border-gray-200"
        >
          <h2 id="order-heading" className="sr-only">
            Your order
          </h2>

          <h3 className="sr-only">Items</h3>
          {formulaire.map((product) => (
            <div
              key={product.id}
              className="flex flex-col space-y-6 border-b border-gray-200 py-10 sm:flex-row sm:space-y-0 sm:space-x-6"
            >
              <img
                src="./images/cl.png"
                alt="ww"
                className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
              />
              <div className="flex flex-auto flex-col">
                <div>
                  <h4 className="font-medium text-gray-900">
                    <a href={product.prenom}>
                      {product.prenom} {product.nom}
                    </a>
                  </h4>
                  <h4 className="font-medium text-gray-900">
                    <a href={product.pharmacie}>
                      Nom De Pharmacie : {product.pharmacie}{" "}
                    </a>
                  </h4>
                  <div className="flex flex-wrap">
                    <span className="font-medium text-gray-900">Sujet: </span>
                    <p className="ml-2 text-gray-700">{product.sujet}</p>
                    <p className="ml-2 text-gray-700">{product.ville}</p>
                  </div>
                  <p className="mt-2 text-sm text-gray-600">
                    Message: {product.mssg}
                  </p>
                </div>
                <div className="mt-6 flex flex-wrap items-center">
                  <div className="flex">
                    <span className="font-medium text-gray-900">Phone: </span>
                    <p className="ml-2 text-gray-700">{product.phone}</p>
                  </div>
                </div>
                <div className="flex mt-2">
                  <span className="font-medium text-gray-900">Email: </span>
                  <p className="ml-2 text-gray-700">{product.email}</p>
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
    </main>
  );
}
