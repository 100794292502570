import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet";
const navigation = [
  { name: "Qui sommes-nous ?  ", href: "/aboutus" },
  { name: "Notre identité de marque", href: "/about" },
  { name: "Nos solutions", href: "/solution" },
  { name: "Merchandising et PLV Pharmacie", href: "/merchan" },
  { name: "Ka-psul", href: "https://ka-psul.com/" },
];

export default function MerchanScreen() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div>
      <div>
        <Helmet>
          <title>Merchandising et PLV Pharmacie</title>
          <meta name="description" content="Ma description" />
          {/* Ajoutez d'autres balises <meta>, <link>, etc. ici */}
        </Helmet>
      </div>
      <Popover as="div" className="relative z-10">
        <nav
          className="relative mx-auto flex max-w-7xl items-center justify-between px-6 pt-2 pb-2 lg:px-8"
          aria-label="Global"
        >
          <div className="flex w-full items-center justify-between lg:w-auto">
            <a href="/">
              <span className="sr-only">
                KA Consulting And Pharmacy Management{" "}
              </span>
              <img className="h-28 w-auto " src="./images/lo.png" alt="" />
            </a>
            <div className="-mr-2 flex items-center lg:hidden">
              <Popover.Button className="focus-ring-inset inline-flex items-center justify-center rounded-md bg-teal-800 bg-opacity-0 p-2 text-cyan-500 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-white">
                <span className="sr-only">Menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>

          <div className="hidden   lg:flex lg:gap-x-12">
            <a href="/aboutus" className="text-sm font-semibold leading-6  ">
              Qui sommes-nous ?
            </a>
            <a href="/about" className="text-sm font-semibold leading-6 ">
              Notre identité de marque
            </a>
            <a href="/solution" className="text-sm font-semibold leading-6 ">
              Nos solutions
            </a>
            <a
              href="/merchan"
              className="text-sm font-semibold leading-6 text-green-500  "
            >
              Merchandising et PLV Pharmacie
            </a>
            <a
              href="https://ka-psul.com/"
              className="text-sm font-semibold leading-6  "
            >
              Ka-psul
            </a>
          </div>
          <div className="hidden lg:flex lg:items-center lg:space-x-6">
            <a
              href="/contact"
              className="rounded-md border border-transparent bg-teal-900 bg-opacity-60 py-2 px-6 text-base font-medium text-white hover:bg-opacity-20"
            >
              Contact
            </a>
          </div>
        </nav>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 origin-top transform p-2 transition lg:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img className="h-8 w-auto" src="./images/lo.png" alt="" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-warm-gray-400 hover:bg-warm-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="space-y-1 px-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-warm-gray-900 hover:bg-warm-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a
                    href="/contact"
                    className="block w-full rounded-md border border-transparent bg-green-400 py-2 px-4 text-center font-medium text-white shadow hover:bg-green-500"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h3 className=" text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl  -mt-12 mb-14">
              Merchandising et PLV Pharmacie
            </h3>
          </div>
        </div>
        <div className="  lg:grid lg:grid-cols-2 lg:gap-2">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="aspect-w-12 -mt-12 aspect-h-5 lg:aspect-none">
                  <img
                    className="rounded-lg object-cover object-center shadow-lg"
                    src="./images/img/14.jpg"
                    alt="Whitney leaning against a railing on a downtown street"
                    width={1184}
                    height={1376}
                  />
                </div>
                <figcaption className="  flex text-sm text-gray-500">
                  <span className="ml-2"> </span>
                </figcaption>
              </figure>
            </div>
          </div>

          <div className="mx-auto max-w-prose text-base lg:max-w-none">
            <h2 className="text-lg font-semibold text-teal-600  ">
              Le merchandising, un langage visuel captivant qui fait danser les
              produits dans les yeux de vos consommateurs !!
            </h2>
            <p>
              Votre pharmacie d’officine n’échappe pas aux règles du commerce de
              détail et doit adopter les techniques du merchandising. En effet,
              pour une pharmacie les produits de parapharmacie constituent à la
              fois un axe de différenciation et un fort potentiel de
              rentabilité, ce qui n’est pas négligeable. L’objectif est le même
              que dans tout commerce de détail : que vous vendiez plus et mieux.
            </p>
            <h2 className="text-lg font-semibold text-teal-600  ">
              Laissez la magie opérer et transformez un seul produit en une
              expérience d’achat inoubliable !
            </h2>{" "}
            <p>
              Chez KA Consulting & Pharmacy Management, nous vous accompagnons à
              mettre en place une stratégie de merchandising sur mesure et
              adaptée aux besoins de votre pharmacie en visibilité et en
              Sell-out et qui présente plusieurs avantages :
            </p>
          </div>

          <div className=" relative lg:col-start-1 lg:row-start-2">
            <ul className="list-decimal  " role="list">
              <li>
                <h3 className="text-lg   font-semibold text-teal-600  ">
                  Augmentation de vos ventes :
                </h3>{" "}
                Un merchandising efficace permet d'optimiser la présentation et
                la mise en valeur des produits en rayon. Cela facilite la
                découverte et L'achat de produits conduisant à une augmentation
                de vos ventes.{" "}
              </li>
              <li>
                <h3 className="text-lg   font-semibold text-teal-600  ">
                  Amélioration de l'expérience client :
                </h3>{" "}
                Un agencement bien pensé et attractif crée une expérience
                agréable pour vos clients. Cela leur permet de trouver
                facilement ce qu'ils cherchent et favorise une circulation
                fluide dans le point de vente grâce à une compréhension profonde
                de leurs habitudes d’achat. Une expérience client positive
                renforce la fidélité et engendre des recommandations positives.{" "}
              </li>
              <li>
                <h3 className="text-lg   font-semibold text-teal-600  ">
                  Mise en avant des produits stratégiques :
                </h3>{" "}
                Un merchandising efficace permet de mettre en avant les produits
                clés, les promotions ou les nouveautés. Cela offre une meilleure
                visibilité à ces produits et encourage leur achat, stimulant
                ainsi leur succès commercial .
              </li>
              <li>
                <h3 className="text-lg   font-semibold text-teal-600  ">
                  Optimisation de l'espace :
                </h3>{" "}
                Un merchandising bien pensé permet d'exploiter pleinement
                l'espace disponible de votre pharmacie. Cela permet de maximiser
                l'offre proposée dans un espace restreint, en utilisant des
                solutions d'agencement optimales et des présentoirs adaptés.{" "}
              </li>
              <li>
                <h3 className="text-lg   font-semibold text-teal-600  ">
                  Renforcement de la marque de votre pharmacie :
                </h3>{" "}
                Une présentation harmonieuse et cohérente des produits renforce
                l'image de marque de votre pharmacie. Cela permet de créer une
                identité visuelle forte et reconnaissable, favorisant ainsi la
                confiance et l'attachement des clients à votre pharmacie.{" "}
              </li>
              <li>
                <h3 className="text-lg   font-semibold text-teal-600  ">
                  Gestion efficace des stocks :
                </h3>{" "}
                Un merchandising efficace facilite la gestion des stocks en
                permettant une meilleure visibilité des produits disponibles.
                Cela permet de limiter les ruptures de stock, d'assurer une
                rotation appropriée des produits et d'optimiser les réassorts en
                fonction de la demande.{" "}
              </li>
            </ul>
          </div>
          <div className="  relative lg:col-start-2 lg:row-start-2">
            <div className="prose prose-indigo mx-auto   lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p className="font-bold text-teal-600   ">
                Le merchandising, l’art subtil de transformer le consommateur en
                véritable admirateur !!
              </p>
              <p>
                En résumé, un merchandising réussi dans votre pharmacie
                d'officine crée un environnement attractif et cohérent,
                favorisant la satisfaction de vos clients et leur fidélisation
                ainsi que la réussite commerciale de votre pharmacie.
              </p>
              <p className=" ">
                Sans oublier l’importance de la PLV, notre expertise en
                Publicité sur le Lieu de Vente vous aidera à valoriser les
                produits ainsi que votre officine .
              </p>
            </div>
            <p className="  ">
              Au sein de votre espace de vente, la signalétique et un balisage
              clairs permettent de guider votre client et être attractif
              visuellement.
            </p>
            <figure>
              <div className="mt-5 aspect-w-12 aspect-h-7 lg:aspect-none">
                <img
                  className="rounded-lg object-cover object-center shadow-lg"
                  src="./images/img/15.jpg"
                  alt="Whitney leaning against a railing on a downtown street"
                  width={1184}
                  height={1376}
                />
              </div>
              <figcaption className="mt-3 flex text-sm text-gray-500">
                <span className="ml-2"> </span>
              </figcaption>
            </figure>
            <p className=" ">
              La vitrine de votre pharmacie sera à adapter de façon pertinente
              (vitrine permanente, vitrine dédiée à une marque, vitrine
              promotionnelle, événementielle ou de saison). Elle contribue à
              votre image de marque.
            </p>{" "}
            <p className=" ">
              Le mobilier de votre officine. Gondoles, présentoirs, comptoirs…
              sont de véritables outils de vente qui mettent en valeur les
              produits
            </p>
            <p className="font-bold text-teal-600 mt-2 ">
              Alors n’attendez plus, faites appel à notre expertise dès
              maintenant !
            </p>
            <p className="font-bold text-teal-600 mt-2 ">
              Parce que nous réinventons votre succès
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
